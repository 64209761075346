import { Location } from 'history';
import { SEO_DESCRIPTION_HOMEPAGE, SEO_TITLE_HOMEPAGE } from 'mk/autogenerated/translations/HomePage.c7450067356077f5df21ca02a937b93d'
import { hasAds } from 'mk/bazaar/common/userUtils';
import { analyticsStoreEvent } from 'mk2/actions';
import { BrandDaysCampaignEntity, BrandDaysCampaignSchema, PostEntity, PostSchema } from 'mk2/apps/forum/schemas';
import AddMenu from 'mk2/apps/home/containers/AddMenu/AddMenu';
import Home from 'mk2/apps/home/containers/Home/Home';
import {
    homeLoadPageTrigger,
    homeLoadTrigger,
} from 'mk2/apps/home/containers/Home/Home.actions';
import {
    homePageReducer,
    BazaarCounts,
    HomePageState,
} from 'mk2/apps/home/containers/Home/Home.reducers';
import saga from 'mk2/apps/home/containers/Home/Home.sagas';
import { getHomeState } from 'mk2/apps/home/containers/Home/Home.selectors';
import { HomeRight } from 'mk2/apps/home/containers/Home/HomeRight';
import {
    EshopPillsSchema,
    EshopPillEntity,
    HomeCounsellingEntity,
    HomeCounsellingSchema,
    HomePhotoblogPostEntity,
    HomePhotoblogPostSchema,
    HomePostEntity,
    HomePostSchema,
} from 'mk2/apps/home/schemas';
import { Count } from 'mk2/components/NotificationsBadge';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import Page, { AnchorLeaderboardProps, BrandingProps } from 'mk2/pages/Page';
import {
    getRequestDeviceIsIOS,
    getRequestDeviceMobile,
    getRequestPermissions,
    getRequestUser,
    AppState,
} from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import {
    BlogPostEntity,
    BlogPostSchema,
    ExperienceEntity,
    ExperienceSchema,
    PhotoDetailSchema,
    StrollerEntity,
    StrollerPhoto,
    StrollerSchema,
    StrollerUserReviewEntity,
    StrollerUserReviewSchema,
    UserEntity,
} from 'mk2/schemas';
import { getDenormalizedEntities, getDenormalizedEntitiesMap, getDenormalizedEntity } from 'mk2/selectors';
import { Store } from 'mk2/store/configureStore';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styles from './HomePage.mscss';

interface OwnProps {}

interface StateProps {
    loadingState: LoadingState;
    errorMessage: string;
    pageLoadingState: LoadingState;
    pageErrorMessage: string;
    homePosts: HomePostEntity[];
    forumPosts: PostEntity[];
    contestPosts: PostEntity[];
    brandDaysCampaign: BrandDaysCampaignEntity;
    brandDaysPosts: PostEntity[];
    photoblogPosts: HomePhotoblogPostEntity[];
    nextPage: number | null;
    bazaarCounts: BazaarCounts;
    requestUser: UserEntity;
    numStrollers: number;
    counsellings: HomeCounsellingEntity[];
    strollerArticles: BlogPostEntity[];
    strollerReviews: StrollerUserReviewEntity[];
    reviewsStrollersMap: Record<number, StrollerEntity>;
    wikiExperiences: ExperienceEntity[];
    lastVisitCountFriends: Count | '' | null;
    isMobile: boolean;
    isIOS: boolean;
    requestUserPermissions: string[];
    place: HomePageState['place'];
    strollers: StrollerEntity[];
    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosMap: Record<number, StrollerPhoto>;
    eshopPills: EshopPillEntity[];
}

interface DispatchProps {
    onLoad(currentUrl: Location);
    onLoadPage(page: number);
    onStoreEvent(name: string, props: any, redirect?: string);
}

type RouteProps = RouteComponentProps<{}>;
export type Props = OwnProps & StateProps & DispatchProps & RouteProps;

export default class HomePage extends Page<OwnProps, StateProps, DispatchProps> {

    public mapStateToProps(): MapStateToPropsParam<StateProps, OwnProps & RouteProps, AppState> {
        return (state: AppState, ownProps: OwnProps & RouteProps): StateProps => {
            const homeState = getHomeState(state);
            const reviewsStrollersMap = getDenormalizedEntitiesMap<StrollerEntity>(
                state, StrollerSchema, homeState.reviewsStrollersIds,
            );
            const strollersPreviewPhotoMap = homeState.strollersPreviewPhotoMap;
            const strollersPhotos = getDenormalizedEntities<StrollerPhoto>(state, PhotoDetailSchema, homeState.strollersPhotosIds);
            const strollersPhotosMap = {};
            strollersPhotos.map(strollerPhoto => strollersPhotosMap[strollerPhoto.id] = strollerPhoto);

            const eshopPills = getDenormalizedEntities<EshopPillEntity>(state, EshopPillsSchema, homeState.eshopPillIds);

            return {
                loadingState: homeState.loadingState,
                errorMessage: homeState.errorMessage,
                pageLoadingState: homeState.pageLoadingState,
                pageErrorMessage: homeState.pageErrorMessage,
                strollers: getDenormalizedEntities<StrollerEntity>(state, StrollerSchema, homeState.strollerIds),
                homePosts: getDenormalizedEntities<HomePostEntity>(state, HomePostSchema, homeState.homePostIds),
                forumPosts: getDenormalizedEntities<PostEntity>(state, PostSchema, homeState.forumPostsIds),
                contestPosts: getDenormalizedEntities<PostEntity>(state, PostSchema, homeState.contestPostsIds),
                eshopPills,
                brandDaysCampaign: getDenormalizedEntity<BrandDaysCampaignEntity>(
                    state,
                    BrandDaysCampaignSchema,
                    homeState.brandDaysCampaignId,
                ),
                brandDaysPosts: getDenormalizedEntities<PostEntity>(state, PostSchema, homeState.brandDaysPostsIds),
                photoblogPosts: getDenormalizedEntities<HomePhotoblogPostEntity>(
                    state,
                    HomePhotoblogPostSchema,
                    homeState.photoblogPostsIds,
                ),
                nextPage: homeState.nextPage,
                bazaarCounts: homeState.bazaarCounts,
                requestUser: getRequestUser(state),
                numStrollers: homeState.numStrollers,
                counsellings: getDenormalizedEntities<HomeCounsellingEntity>(
                    state,
                    HomeCounsellingSchema,
                    homeState.homeCounsellingIds,
                ),
                lastVisitCountFriends: homeState.lastVisitCountFriends,
                isMobile: getRequestDeviceMobile(state),
                isIOS: getRequestDeviceIsIOS(state),
                requestUserPermissions: getRequestPermissions(state),
                place: homeState.place,
                strollerArticles: getDenormalizedEntities<BlogPostEntity>(
                    state,
                    BlogPostSchema,
                    homeState.strollerArticlesIds,
                    ),
                strollerReviews: getDenormalizedEntities<StrollerUserReviewEntity>(
                    state,
                    StrollerUserReviewSchema,
                    homeState.strollerReviewsIds,
                ),
                reviewsStrollersMap,
                wikiExperiences: getDenormalizedEntities<ExperienceEntity>(
                    state,
                    ExperienceSchema,
                    homeState.wikiExperiencesIds,
                ),
                strollersPreviewPhotoMap,
                strollersPhotosMap,
            };
        };
    }

    public mapDispatchToProps(): MapDispatchToPropsObject<DispatchProps> {
        return {
            onLoad: homeLoadTrigger,
            onLoadPage: homeLoadPageTrigger,
            onStoreEvent: analyticsStoreEvent,
        };
    }

    public getMetaTitle(props: Props): string {
        return SEO_TITLE_HOMEPAGE;
    }

    public getMetaDescription(props: Props): string {
        return SEO_DESCRIPTION_HOMEPAGE;
    }

    public render(props: Props): false | JSX.Element {
        return (
            <Home {...props} />
        );
    }

    public hasMobileLogo(props: Props): boolean {
        return true;
    }

    public getHeaderRight(props: Props): React.ReactElement {
        return (
            props.isMobile && (
                <AddMenu
                    className={styles.HomePage__header__menu}
                    requestUser={props.requestUser}
                    location={props.location}
                />
            )
        );
    }

    public renderRight(props: Props): JSX.Element | null {
        return <HomeRight {...props} />;
    }

    public hasHeaderBackButton(props: Props): boolean {
        return false;
    }

    public getBrandingProps(props: Props): BrandingProps {
        return {
            zone: 'Homepage - Branding',
        };
    }

    public getAnchorLeaderboardProps(props: Props): AnchorLeaderboardProps {
        const { isMobile, requestUser } = props;

        if (!isMobile || requestUser && !hasAds(requestUser)) {
            return null;
        }

        return {
            zone: 'Homepage - Anchor Ad Leaderboard (Mobile)',
        };
    }

    public registerInStore(store: Store<AppState>) {
        store.injectReducer('containers.home.home', homePageReducer);
        store.injectSaga('containers.home.home', saga);
    }
}
